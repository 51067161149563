/* global SnackBar */
import Sortable from 'sortablejs';
import axios from 'axios';
import _debounce from './_debounce';

const csrf = document.querySelector('#csrf').value;
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf;

/**
 * Editors
 */
const editorConfig = {
  'useSearch': false,
  'language': 'pl',
  'direction': 'ltr',
  'enter': 'BR',
  'toolbarAdaptive': false,
  'toolbarSticky': false,
  'showCharsCounter': false,
  'showWordsCounter': false,
  'showXPathInStatusbar': false,
  'minHeight': 50,
  'buttons': 'bold,italic,underline,ul,ol,undo,redo,source'
};

const textareaElements = document.querySelectorAll('textarea');
if (textareaElements) {
  textareaElements.forEach(element => {
    new Jodit(element, editorConfig);
  });
}

/**
 * Uploaders
 */
const uploadGalleryElements = document.querySelectorAll('input[type=file]');
if (uploadGalleryElements) {
  uploadGalleryElements.forEach(uploadGalleryElement => {
    uploadGalleryElement.addEventListener('change', () => {
      uploadGalleryElement.form.classList.add('busy');
      uploadGalleryElement.form.submit();
    });
  });
}

/**
 * Delete button
 */
const deleteButtonElements = document.querySelectorAll('button[data-action="delete"]');
if (deleteButtonElements) {
  deleteButtonElements.forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault();
      if (confirm('Czy chcesz usunąć to zdjęcie?')) {
        element.form.submit();
      }
    });
  });
}

/**
 * Sortable
 */
const galleryElement = document.querySelector('#gallery');
if (galleryElement) {
  Sortable.create(galleryElement, {
    onEnd: function () {
      updateGallery(galleryElement);
    }
  });
}

/**
 * Slider sortable
 */
const sortableSlideElements = document.querySelectorAll('[data-sortable]');
if (sortableSlideElements) {
  sortableSlideElements.forEach(element => {
    Sortable.create(element, {
      onEnd: function () {
        updatePriority(element);
      }
    });
  });
}

function updateGallery(wrapperElement) {
  let data = [];
  Object.keys(wrapperElement.children).forEach(key => {
    data.push({
      id: parseInt(wrapperElement.children[key].dataset.id, 10),
      priority: parseInt(key, 10)
    });
  });

  axios.post(`/admin/hero/priority`, {
    data
  });
}

function updatePriority(wrapperElement) {
  let data = [];
  Object.keys(wrapperElement.children).forEach(key => {
    data.push({
      id: parseInt(wrapperElement.children[key].dataset.id, 10),
      priority: parseInt(key, 10)
    });
  });

  axios.post('/admin/about-slides/priority', {
    data: data,
    slider: wrapperElement.dataset.sortable
  });
}

/**
 * Photos removal
 */
let photoDeleteButtons = document.querySelectorAll('button[data-action=delete]');
if (photoDeleteButtons) {
  photoDeleteButtons.forEach(button => {
    button.addEventListener('pointerdown', e => {
      e.stopPropagation();
    }, {
      passive: true
    });
  });
}

/**
 * Room sortable slider
 */
const sortableRoomSlideElement = document.querySelector('[data-roomsortable]');
if (sortableRoomSlideElement) {
  Sortable.create(sortableRoomSlideElement, {
    onEnd: function () {
      updateRoomPriority(sortableRoomSlideElement);
    }
  });
}

function updateRoomPriority(wrapperElement) {
  let data = [];
  Object.keys(wrapperElement.children).forEach(key => {
    data.push(wrapperElement.children[key].dataset.filename);
  });

  axios.post(wrapperElement.dataset.roomsortable, {
    data: data
  }).then(() => {
    SnackBar({
      status: 'success',
      message: 'Kolejność zapisana',
      dismissible: false,
      fixed: true
    });
  });
}

const postIdAndReloadElements = document.querySelectorAll('[data-postidandreload]');
if (postIdAndReloadElements) {
  postIdAndReloadElements.forEach(postIdAndReloadElement => {
    postIdAndReloadElement.addEventListener('click', e => {
      if (postIdAndReloadElement.dataset.hasOwnProperty('message')) {
        if (!confirm(postIdAndReloadElement.dataset.message)) {
          return;
        }
      } else {
        if (!confirm('Czy na pewno chcesz kontynuować?')) {
          return;
        }
      }

      postAndReload(postIdAndReloadElement.dataset.postidandreload, {
        id: postIdAndReloadElement.dataset.id
      });
    });
  });
}

function postAndReload(url, data) {
  axios.post(url, data).then(response => {
    window.location.reload();
  });
}

const inputFields = document.querySelectorAll('[data-updateinput][data-id]');
inputFields.forEach(inputField => {
  inputField.addEventListener('change', _debounce(function () {
      updateInputField(inputField.dataset.updateinput, inputField.dataset.id, inputField.value);
  }, 500));
});

function updateInputField(url, id, value) {
  axios.post(url, {
    id,
    value
  }).then(response => {
    SnackBar({
      status: 'success',
      message: 'Zmiana została zapisana',
      dismissible: false,
      fixed: true
    });
  });
}
